import Cookies from './vendor/js.cookie-2.2.1.min.js'

// JavaScript Document
jQuery(document).ready(function($){

    var $document = $(document);

    $(".rev-scroll-btn").click(function() {

        if ($(window).innerWidth() > 1000) {

            $('html, body').animate({
                scrollTop: $("#intro").offset().top - 80
            });

        } else {

            $('html, body').animate({
                scrollTop: $("#intro").offset().top - 58
            });

        }
    });
    //
    $('.using-image[style*="/wp-content/uploads/2017/03/burnbake-shadowline.png"]').addClass("css-contain");
    $('#search-results .result').attr("style","");

    $('.wc-pao-addon-choose-bank-holiday label, .wc-pao-addon-campsite label, .wc-pao-addon-lead-name label, .wc-pao-addon-adults label, .wc-pao-addon-additional-tent label, .wc-pao-addon-extra-adults label, .wc-pao-addon-children label, .wc-pao-addon-large-tent-supplements label, .wc-pao-addon-car-registration-numbers label, .wc-pao-addon-extra-car label, .wc-pao-addon-booking-fee label, .wc-pao-addon-email-newsletter-signup label').append('<i class="fa fa-info-circle" aria-hidden="true"></i>');

    $('.wc-pao-addon label i').click(function() {
        $(this).closest('.wc-pao-addon').find('.wc-pao-addon-description').toggle();
    });
    $('.wc-pao-addon-description').click(function() {
        $(this).toggle();
    });

    if ($('.property-carousel__inner').length) {
        $('.property-carousel__inner').slick({
            dots: false,
            infinite: true,
            speed: 600,
            slidesToShow: 2,
            slidesToScroll: 1,
            appendArrows: $('.property-carousel__arrows'),
            responsive: [
                {
                breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                }
            ]
        });
    }

    if ($('.property-gallery__inner').length) {
        $('.property-gallery__inner').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            }
        });
    }

    function PopUp(){
        $.magnificPopup.open({
            items: {
                src: '#alertmodal'
            },
            type: 'inline',
            closeBtnInside: true
        });
    }

    /* var check_cookie = Cookies('alertmodal');
    if (check_cookie == undefined) {
        Cookies.set('alertmodal', 'yes', {expires: 1}); // Expires Cookie in 1 day

        setTimeout(function(){
            if ($('#alertmodal').length) {
                PopUp();
            }
        },1000); // 1000 to load it after 1 second from page load
    } */

    $('.property-form__form-item select[name="acc-type"]').on('change', function() {
        var value = $(this).val();
        
        if( value == 'camping' ) {
            location.href = "https://burnbake.anytimebooking.eu/place_booking";
        }
        
        if( value == 'rempstone' ) {
            location.href = "/rempstone-farmhouse/";
        }
    });

    $('.property-list-filter__select').on('change', function(e) {
        $('.property-form input[name=orderby]').val( e.target.value );
        $('.property-form').trigger('submit');
    });

	if ($('.datepicker').length) {
    	$(this).pickadate({formatSubmit:'yyyy-mm-dd', hiddenName: true})
	}


	// Load live price data asychronously if search returns any properties
	if( $('#property-results').length && $('#property-results .property').length ) {

		// Check if form values are set
		if( $('.property-form input[name="startdate"]').val() && $('.property-form select[name="numbernights"]').val() ) {

			// API request for price data
			let formData = $('.property-form').serializeArray();
			formData = formData.concat([
				{name: "action", value: 'scprices'}
			]);
			$.post(
				$('#property-results').data('prices-endpoint'),
				formData,
				function (xml){
					if( $(xml).find('propertycount').last().text() > 0 ) {
						// Loop through properties and set price data
						$(xml).find('property').each(function(){
							let propertyCode = $(this).find('propertycode').text();
							let price = $(this).find('prices').first().find('rate').text();
							let priceDiv = $('.property[data-property-code="'+propertyCode+'"]').find('.property__price');
							priceDiv.fadeOut('slow', function() {
								$(this).html('£'+ parseFloat(price).toFixed(2) ).fadeIn('slow');
							});
						});
					}
				}
			);
		}
	}

});
